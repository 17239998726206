div.header {
  background: none;
  border-bottom: none;
  cursor: pointer;
  color: var(--gray);
  text-decoration: none;
  padding: 1em;
  margin-bottom: 0;
}

div.header:hover {
  color: var(--gray-dark);
  text-decoration: none;
}
