/* PRIMARY */
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: var(--primary-100);
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: var(--primary-300);
}
.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: var(--primary-200);
}
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: var(--primary-600);
  border-color: var(--primary-700);
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-700);
  border-color: var(--primary-800);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}
.btn-outline-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.form-control:focus {
  border-color: var(--primary-200);
}
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: var(--primary-300);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  background-color: var(--primary-100);
  border-color: var(--primary-100);
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: var(--primary-05);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label:before {
  background-color: var(--primary-05);
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: var(--primary-05);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: var(--primary-05);
}
.custom-select:focus {
  border-color: var(--primary-300);
  box-shadow: 0 0 0 0.2rem var(--primary-025);
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--primary-300);
  box-shadow: 0 0 0 0.2rem var(--primary-025);
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f9fafc, 0 0 0 0.2rem var(--primary-025);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f9fafc, 0 0 0 0.2rem var(--primary-025);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f9fafc, 0 0 0 0.2rem var(--primary-025);
}
.custom-range::-webkit-slider-thumb {
  background-color: var(--primary);
}
.custom-range::-moz-range-thumb {
  background-color: var(--primary);
}
.custom-range::-moz-range-thumb:active {
  background-color: var(--primary-100);
}
.custom-range::-ms-thumb {
  background-color: var(--primary);
}
.custom-range::-ms-thumb:active {
  background-color: var(--primary-100);
}
.badge-primary {
  background-color: var(--primary);
}
a.badge-primary:focus,
a.badge-primary:hover {
  background-color: var(--primary-600);
}
a.badge-primary.focus,
a.badge-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-05);
}
.alert-primary {
  border: 1px solid var(--primary-300);
  border-left: 3px solid var(--primary);
  background-color: var(--primary-100);
  color: var(--primary-800);
}
.alert-primary hr {
  border-top-color: var(--primary-200);
}
.alert-primary .alert-link {
  color: var(--primary-900);
}
.progress-bar {
  background-color: var(--primary);
}
.list-group-item-primary {
  background-color: var(--primary-100);
  color: var(--primary-800);
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: var(--primary-200);
  color: var(--primary-800);
}
.list-group-item-primary.list-group-item-action.active {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: var(--primary-700) !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary-800) !important;
}
.text-primary-01 {
  color: var(--primary-01) !important;
}
.bg-primary-01 {
  background-color: var(--primary-01) !important;
}
.b-primary-01 {
  border-color: var(--primary-01) !important;
}
.bt-primary-01,
.by-primary-01 {
  border-top-color: var(--primary-01) !important;
}
.br-primary-01,
.bx-primary-01 {
  border-right-color: var(--primary-01) !important;
}
.bb-primary-01,
.by-primary-01 {
  border-bottom-color: var(--primary-01) !important;
}
.bl-primary-01,
.bx-primary-01 {
  border-left-color: var(--primary-01) !important;
}
.text-primary-02 {
  color: var(--primary-02) !important;
}

.bg-primary-02 {
  background-color: var(--primary-02) !important;
}
.b-primary-02 {
  border-color: var(--primary-02) !important;
}
.bt-primary-02,
.by-primary-02 {
  border-top-color: var(--primary-02) !important;
}
.br-primary-02,
.bx-primary-02 {
  border-right-color: var(--primary-02) !important;
}
.bb-primary-02,
.by-primary-02 {
  border-bottom-color: var(--primary-02) !important;
}
.bl-primary-02,
.bx-primary-02 {
  border-left-color: var(--primary-02) !important;
}
.text-primary-03 {
  color: var(--primary-03) !important;
}
.bg-primary-03 {
  background-color: var(--primary-03) !important;
}
.b-primary-03 {
  border-color: var(--primary-03) !important;
}
.bt-primary-03,
.by-primary-03 {
  border-top-color: var(--primary-03) !important;
}
.br-primary-03,
.bx-primary-03 {
  border-right-color: var(--primary-03) !important;
}
.bb-primary-03,
.by-primary-03 {
  border-bottom-color: var(--primary-03) !important;
}
.bl-primary-03,
.bx-primary-03 {
  border-left-color: var(--primary-03) !important;
}
.text-primary-04 {
  color: var(--primary-04) !important;
}
.bg-primary-04 {
  background-color: var(--primary-04) !important;
}
.b-primary-04 {
  border-color: var(--primary-04) !important;
}
.bt-primary-04,
.by-primary-04 {
  border-top-color: var(--primary-04) !important;
  border-top-style: solid;
  border-top-width: 1px;
}
.br-primary-04,
.bx-primary-04 {
  border-right-color: var(--primary-04) !important;
  border-right-style: solid;
  border-right-width: 1px;
}
.bb-primary-04,
.by-primary-04 {
  border-bottom-color: var(--primary-04) !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bl-primary-04,
.bx-primary-04 {
  border-left-color: var(--primary-04) !important;
  border-left-style: solid;
  border-left-width: 1px;
}
.text-primary-05 {
  color: var(--primary-05) !important;
}
.bg-primary-05 {
  background-color: var(--primary-05) !important;
}
.b-primary-05 {
  border-color: var(--primary-05) !important;
  border-style: solid;
  border-width: 1px;
}
.bt-primary-05,
.by-primary-05 {
  border-top-color: var(--primary-05) !important;
  border-top-style: solid;
  border-top-width: 1px;
}
.br-primary-05,
.bx-primary-05 {
  border-right-color: var(--primary-05) !important;
  border-right-style: solid;
  border-right-width: 1px;
}
.bb-primary-05,
.by-primary-05 {
  border-bottom-color: var(--primary-05) !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bl-primary-05,
.bx-primary-05 {
  border-left-color: var(--primary-05) !important;
  border-left-style: solid;
  border-left-width: 1px;
}
.text-primary-06 {
  color: var(--primary-06) !important;
}
.bg-primary-06 {
  background-color: var(--primary-06) !important;
}
.b-primary-06 {
  border-color: var(--primary-06) !important;
}
.bt-primary-06,
.by-primary-06 {
  border-top-color: var(--primary-06) !important;
}
.br-primary-06,
.bx-primary-06 {
  border-right-color: var(--primary-06) !important;
}
.bb-primary-06,
.by-primary-06 {
  border-bottom-color: var(--primary-06) !important;
}
.bl-primary-06,
.bx-primary-06 {
  border-left-color: var(--primary-06) !important;
}
.text-primary-07 {
  color: var(--primary-07) !important;
}
.bg-primary-07 {
  background-color: var(--primary-07) !important;
}
.b-primary-07 {
  border-color: var(--primary-07) !important;
}
.bt-primary-07,
.by-primary-07 {
  border-top-color: var(--primary-07) !important;
}
.br-primary-07,
.bx-primary-07 {
  border-right-color: var(--primary-07) !important;
}
.bb-primary-07,
.by-primary-07 {
  border-bottom-color: var(--primary-07) !important;
}
.bl-primary-07,
.bx-primary-07 {
  border-left-color: var(--primary-07) !important;
}
.text-primary-08 {
  color: var(--primary-08) !important;
}
.bg-primary-08 {
  background-color: var(--primary-08) !important;
}
.b-primary-08 {
  border-color: var(--primary-08) !important;
}
.bt-primary-08,
.by-primary-08 {
  border-top-color: var(--primary-08) !important;
}
.br-primary-08,
.bx-primary-08 {
  border-right-color: var(--primary-08) !important;
}
.bb-primary-08,
.by-primary-08 {
  border-bottom-color: var(--primary-08) !important;
}
.bl-primary-08,
.bx-primary-08 {
  border-left-color: var(--primary-08) !important;
}
.text-primary-09 {
  color: var(--primary-09) !important;
}
.bg-primary-09 {
  background-color: var(--primary-09) !important;
}
.b-primary-09 {
  border-color: var(--primary-09) !important;
}
.bt-primary-09,
.by-primary-09 {
  border-top-color: var(--primary-09) !important;
}
.br-primary-09,
.bx-primary-09 {
  border-right-color: var(--primary-09) !important;
}
.bb-primary-09,
.by-primary-09 {
  border-bottom-color: var(--primary-09) !important;
}
.bl-primary-09,
.bx-primary-09 {
  border-left-color: var(--primary-09) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.b-primary {
  border-color: var(--primary) !important;
}

.bt-primary,
.by-primary {
  border-top-color: var(--primary) !important;
}

.br-primary,
.bx-primary {
  border-right-color: var(--primary) !important;
}

.bb-primary,
.by-primary {
  border-bottom-color: var(--primary) !important;
}

.bl-primary,
.bx-primary {
  border-left-color: var(--primary) !important;
}
.tabs-line .nav-tabs .nav-link.active,
.tabs-line .nav-tabs .nav-link.active:hover {
  border-bottom-color: var(--primary);
  box-shadow: inset 2px -6px 0 -3px var(--primary);
}
.modal-primary .modal-text {
  color: var(--primary-900) !important;
}

.modal-primary .modal-body .modal-icon,
.modal-primary .modal-body > h6,
.modal-primary .modal-header > button {
  color: var(--primary) !important;
}

.modal-primary .modal-body,
.modal-primary .modal-header {
  background-color: var(--primary-200) !important;
}

.modal-primary .modal-content {
  border-color: var(--primary-100) !important;
}

.modal-primary .modal-footer {
  background-color: var(--primary-100) !important;
}
.modal-outline-primary .modal-content {
  border-color: var(--primary) !important;
}

.modal-outline-primary .modal-content h6 {
  color: var(--primary) !important;
}
.alert-primary .alert-icon {
  color: var(--primary);
}
.custom-card--border-dash.custom-card--color-primary-01,
.custom-card--border-dot.custom-card--color-primary-01,
.custom-card--border.custom-card--color-primary-01 {
  border-color: var(--primary-01);
}

.custom-card--border-dash.custom-card--color-primary-02,
.custom-card--border-dot.custom-card--color-primary-02,
.custom-card--border.custom-card--color-primary-02 {
  border-color: var(--primary-02);
}

.custom-card--border-dash.custom-card--color-primary-03,
.custom-card--border-dot.custom-card--color-primary-03,
.custom-card--border.custom-card--color-primary-03 {
  border-color: var(--primary-03);
}

.custom-card--border-dash.custom-card--color-primary-04,
.custom-card--border-dot.custom-card--color-primary-04,
.custom-card--border.custom-card--color-primary-04 {
  border-color: var(--primary-04);
}

.custom-card--border-dash.custom-card--color-primary-05,
.custom-card--border-dot.custom-card--color-primary-05,
.custom-card--border.custom-card--color-primary-05 {
  border-color: var(--primary-05);
}

.custom-card--border-dash.custom-card--color-primary-06,
.custom-card--border-dot.custom-card--color-primary-06,
.custom-card--border.custom-card--color-primary-06 {
  border-color: var(--primary-06);
}

.custom-card--border-dash.custom-card--color-primary-07,
.custom-card--border-dot.custom-card--color-primary-07,
.custom-card--border.custom-card--color-primary-07 {
  border-color: var(--primary-07);
}

.custom-card--border-dash.custom-card--color-primary-08,
.custom-card--border-dot.custom-card--color-primary-08,
.custom-card--border.custom-card--color-primary-08 {
  border-color: var(--primary-08);
}

.custom-card--border-dash.custom-card--color-primary-09,
.custom-card--border-dot.custom-card--color-primary-09,
.custom-card--border.custom-card--color-primary-09 {
  border-color: var(--primary-09);
}
.custom-card--border-dash.custom-card--color-primary,
.custom-card--border-dot.custom-card--color-primary,
.custom-card--border.custom-card--color-primary {
  border-color: var(--primary);
}
.custom-card--side-border.custom-card--color-primary-01 {
  border-left-color: var(--primary-01);
}

.custom-card--side-border.custom-card--color-primary-02 {
  border-left-color: var(--primary-02);
}

.custom-card--side-border.custom-card--color-primary-03 {
  border-left-color: var(--primary-03);
}

.custom-card--side-border.custom-card--color-primary-04 {
  border-left-color: var(--primary-04);
}

.custom-card--side-border.custom-card--color-primary-05 {
  border-left-color: var(--primary-05);
}

.custom-card--side-border.custom-card--color-primary-06 {
  border-left-color: var(--primary-06);
}

.custom-card--side-border.custom-card--color-primary-07 {
  border-left-color: var(--primary-07);
}

.custom-card--side-border.custom-card--color-primary-08 {
  border-left-color: var(--primary-08);
}

.custom-card--side-border.custom-card--color-primary-09 {
  border-left-color: var(--primary-09);
}
.custom-card--side-border.custom-card--color-primary {
  border-left-color: var(--primary);
}

.custom-card--background.custom-card--color-primary-01 {
  background-color: var(--primary-01);
}

.custom-card--background.custom-card--color-primary-02 {
  background-color: var(--primary-02);
}

.custom-card--background.custom-card--color-primary-03 {
  background-color: var(--primary-03);
}

.custom-card--background.custom-card--color-primary-04 {
  background-color: var(--primary-04);
}

.custom-card--background.custom-card--color-primary-05 {
  background-color: var(--primary-05);
}

.custom-card--background.custom-card--color-primary-06 {
  background-color: var(--primary-06);
}

.custom-card--background.custom-card--color-primary-07 {
  background-color: var(--primary-07);
}

.custom-card--background.custom-card--color-primary-08 {
  background-color: var(--primary-08);
}

.custom-card--background.custom-card--color-primary-09 {
  background-color: var(--primary-09);
}
.custom-card--background.custom-card--color-primary {
  background-color: var(--primary);
}
.custom-card-header--background.custom-card-header--color-primary-01 {
  background-color: var(--primary-01);
}

.custom-card-header--background.custom-card-header--color-primary-02 {
  background-color: var(--primary-02);
}

.custom-card-header--background.custom-card-header--color-primary-03 {
  background-color: var(--primary-03);
}

.custom-card-header--background.custom-card-header--color-primary-04 {
  background-color: var(--primary-04);
}

.custom-card-header--background.custom-card-header--color-primary-05 {
  background-color: var(--primary-05);
}

.custom-card-header--background.custom-card-header--color-primary-06 {
  background-color: var(--primary-06);
}

.custom-card-header--background.custom-card-header--color-primary-07 {
  background-color: var(--primary-07);
}

.custom-card-header--background.custom-card-header--color-primary-08 {
  background-color: var(--primary-08);
}

.custom-card-header--background.custom-card-header--color-primary-09 {
  background-color: var(--primary-09);
}
.custom-card-header--background.custom-card-header--color-primary {
  background-color: var(--primary);
}
.custom-card-header--border.custom-card-header--color-primary-01 {
  border-bottom-color: var(--primary-01);
}

.custom-card-header--border.custom-card-header--color-primary-02 {
  border-bottom-color: var(--primary-02);
}

.custom-card-header--border.custom-card-header--color-primary-03 {
  border-bottom-color: var(--primary-03);
}

.custom-card-header--border.custom-card-header--color-primary-04 {
  border-bottom-color: var(--primary-04);
}

.custom-card-header--border.custom-card-header--color-primary-05 {
  border-bottom-color: var(--primary-05);
}

.custom-card-header--border.custom-card-header--color-primary-06 {
  border-bottom-color: var(--primary-06);
}

.custom-card-header--border.custom-card-header--color-primary-07 {
  border-bottom-color: var(--primary-07);
}

.custom-card-header--border.custom-card-header--color-primary-08 {
  border-bottom-color: var(--primary-08);
}

.custom-card-header--border.custom-card-header--color-primary-09 {
  border-bottom-color: var(--primary-09);
}

.sidebar__brand,
.sidebar__brand:hover,
.sidebar__link {
  color: var(--primary);
}

.sidebar__link:hover {
  color: var(--primary);
}
.navbar-dark .nav-accent .nav-item .nav-link.active,
.navbar-light .nav-accent .nav-item .nav-link.active {
  box-shadow: inset 0 -3px 0 0 var(--primary);
}

.theme-dark-primary {
  --theme-theme-color: var(--primary);
}

.navbar-dark-primary {
  --theme-navbar-brand-color: var(--primary);
  --theme-navbar-menu-item-accent-color: var(--primary);
}

.layout--theme--light--primary {
  --theme-theme-color: var(--primary);
  --theme-sidebar-brand-color: var(--primary);
  --theme-menu-accent-color: var(--primary);
  --theme-menu-accent-color-hover: var(--primary);
  --theme-navbar-brand-color: var(--primary);
  --theme-navbar-menu-item-accent-color: var(--primary);
  --theme-navbar-dropdown-active-background: var(--primary);
}

.theme-light-primary {
  --theme-theme-color: var(--primary);
}

.navbar-light-primary {
  --theme-navbar-brand-color: var(--primary);
  --theme-navbar-menu-item-accent-color: var(--primary);
  --theme-navbar-dropdown-active-background: var(--primary);
}

.sidebar-light-key {
  --theme-sidebar-brand-color: var(--primary);
  --theme-menu-accent-color: var(--primary);
  --theme-menu-accent-color-hover: var(--primary);
}
.layout--theme--color--primary {
  --theme-theme-color: #44bcc5;
  --theme-sidebar-background: #44bcc5;
  --theme-sidebar-border: none;
  --theme-sidebar-border-slim-submenu: 1px solid hsla(0, 0%, 100%, 0.5);
  --theme-sidebar-brand-color: #fff;
  --theme-sidebar-badge-override: ;
  --theme-sidebar-close-color: ;
  --theme-sidebar-text-color: #fff;
  --theme-sidebar-text-color-muted: hsla(0, 0%, 100%, 0.7);
  --theme-sidebar-link-color: #fff;
  --theme-sidebar-link-color-hover: hsla(0, 0%, 100%, 0.9);
  --theme-sidebar-link-color-muted: hsla(0, 0%, 100%, 0.7);
  --theme-sidebar-scroll-rail-color: transparent;
  --theme-sidebar-scroll-thumb-color: transparent;
  --theme-menu-accent-color: #fff;
  --theme-menu-accent-color-hover: #fff;
  --theme-menu-background-color: transparent;
  --theme-menu-text-color: hsla(0, 0%, 100%, 0.7);
  --theme-menu-icon-color: hsla(0, 0%, 100%, 0.7);
  --theme-menu-slim-icon-color: ;
  --theme-menu-arrow-color: hsla(0, 0%, 100%, 0.7);
  --theme-menu-background-color-hover: transparent;
  --theme-menu-slim-background-color-hover: transparent;
  --theme-menu-text-color-hover: #fff;
  --theme-menu-slim-text-color-hover: #fff;
  --theme-menu-icon-color-hover: #fff;
  --theme-menu-slim-icon-color-hover: #fff;
  --theme-menu-arrow-color-hover: #fff;
  --theme-menu-background-color-active: transparent;
  --theme-menu-slim-background-color-active: transparent;
  --theme-menu-text-color-active: #fff;
  --theme-menu-slim-text-color-active: #fff;
  --theme-menu-icon-color-active: #fff;
  --theme-menu-slim-icon-color-active: #fff;
  --theme-menu-arrow-color-active: #fff;
  --theme-navbar-background: #44bcc5;
  --theme-navbar-brand-color: #fff;
  --theme-navbar-between-border-color: #44bcc5;
  --theme-navbar-menu-item-color: hsla(0, 0%, 100%, 0.8);
  --theme-navbar-menu-item-hover-color: #fff;
  --theme-navbar-menu-item-active-background: rgba(31, 45, 61, 0.1);
  --theme-navbar-menu-item-active-color: #fff;
  --theme-navbar-menu-icon-w-badge-color: #8ad5db;
  --theme-navbar-menu-item-accent-color: #fff;
  --theme-navbar-menu-item-accent-hover-color: hsla(0, 0%, 100%, 0.5);
  --theme-navbar-dropdown-active-color: #fff;
  --theme-navbar-dropdown-active-background: #44bcc5;
}

.layout--theme--color--primary
  .layout__navbar
  .navbar-themed
  .icon-with-badge
  .icon-with-badge__badge {
  background-color: #8ad5db !important;
}

.layout--theme--color--primary .layout__sidebar .sidebar .badge {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.navbar-color-primary,
.theme-color-primary {
  --theme-theme-color: #44bcc5;
}

.navbar-color-primary {
  --theme-navbar-background: var(--primary);
  --theme-navbar-between-border-color: var(--primary);
  --theme-navbar-menu-icon-w-badge-color: var(--primary-300);
  --theme-navbar-dropdown-active-background: var(--primary);
}

.navbar-color-primary.navbar .icon-with-badge .icon-with-badge__badge,
.navbar-color-primary
  .navbar.navbar-themed
  .icon-with-badge
  .icon-with-badge__badge {
  background-color: var(--primary-300) !important;
}

.sidebar-color-key {
  --theme-theme-color: var(--primary);
  --theme-sidebar-background: var(--primary);
}
.layout--theme--dark--primary {
  --theme-menu-text-color-active: var(--primary);
  --theme-menu-icon-color-active: var(--primary);
  --theme-menu-text-color-hover: var(--primary);
  --theme-menu-icon-color-hover: var(--primary);
  --theme-sidebar-background: var(--secondary);
  --theme-theme-color: var(--primary);
  --theme-sidebar-brand-color: var(--primary);
  --theme-menu-accent-color: var(--primary);
  --theme-menu-accent-color-hover: var(--primary);
  --theme-navbar-brand-color: var(--primary);
  --theme-navbar-menu-item-accent-color: var(--primary);
  --theme-navbar-dropdown-active-background: var(--primary);
}
.rbt-token {
  background-color: var(--primary);
}
.Toastify__toast--info .fa-info {
  color: var(--primary);
}

.Toastify__toast--info {
  border-left: 3px solid var(--primary);
}

.rbt-aux .spinner-border-sm {
  color: var(--primary);
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected_span {
  background: var(--primary);
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: var(--primary);
  border-color: var(--primary-06);
}

.CalendarDay__selected_span {
  background: var(--primary-300);
  border: 1px double var(--primary);
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected {
  background: var(--primary-200);
  border-color: var(--primary-200);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--primary);
  border: 1px double var(--primary);
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: 1px double var(--primary-300);
}

.CalendarDay__hovered_span:active {
  background: var(--primary-300);
  border: 1px double var(--primary-300);
  color: var(--primary);
}

.PresetDateRangePicker_button {
  border: 2px solid var(--primary);
  color: var(--primary);
}

.PresetDateRangePicker_button__selected {
  background: var(--primary);
}

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-right: 33px solid var(--primary);
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid var(--primary-300);
}

.DayPickerKeyboardShortcuts_show__topRight:before {
  border-right: 33px solid var(--primary);
}

.DayPickerKeyboardShortcuts_show__topRight:hover:before {
  border-right: 33px solid var(--primary-300);
}

.DayPickerKeyboardShortcuts_show__topLeft:before {
  border-left: 33px solid var(--primary);
}

.DayPickerKeyboardShortcuts_show__topLeft:hover:before {
  border-left: 33px solid var(--primary-300);
}

.DateInput_input__focused {
  border-bottom: 2px solid var(--primary-300);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--primary);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--primary);
}

/* SECONDARY */
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: var(--secondary-100);
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: var(--ligth-2-secondary);
}
.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: var(--secondary-200);
}
.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: var(--secondary-600);
  border-color: var(--secondary-700);
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--secondary-700);
  border-color: var(--secondary-800);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}
.btn-outline-secondary {
  border-color: var(--secondary);
  color: var(--secondary);
}
.btn-outline-secondary:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--secondary);
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}
.badge-secondary {
  background-color: var(--secondary-200);
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  background-color: var(--secondary-700);
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}
.alert-secondary {
  background-color: var(--secondary-100);
  border-color: var(--secondary-200);
  color: var(--secondary-800);
}
.alert-secondary hr {
  border-top-color: var(--secondary-200);
}
.alert-secondary .alert-link {
  color: var(--secondary-900);
}
.list-group-item-secondary {
  background-color: var(--secondary-600);
  color: var(--secondary-800);
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: var(--secondary-200);
  color: var(--secondary-800);
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: var(--secondary-800);
  border-color: var(--secondary-800);
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: var(--secondary-700) !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: var(--secondary-800) !important;
}

.text-secondary-01 {
  color: var(--secondary-01) !important;
}

.text-secondary-100 {
  color: var(--secondary-100) !important;
}

.bg-secondary-01 {
  background-color: var(--secondary-01) !important;
}

.b-secondary-01 {
  border-color: var(--secondary-01) !important;
}

.bt-secondary-01,
.by-secondary-01 {
  border-top-color: var(--secondary-01) !important;
}

.br-secondary-01,
.bx-secondary-01 {
  border-right-color: var(--secondary-01) !important;
}

.bb-secondary-01,
.by-secondary-01 {
  border-bottom-color: var(--secondary-01) !important;
}

.bl-secondary-01,
.bx-secondary-01 {
  border-left-color: var(--secondary-01) !important;
}

.text-secondary-02 {
  color: var(--secondary-02) !important;
}

.text-secondary-200 {
  color: var(--secondary-200) !important;
}

.bg-secondary-02 {
  background-color: var(--secondary-02) !important;
}

.b-secondary-02 {
  border-color: var(--secondary-02) !important;
}

.bt-secondary-02,
.by-secondary-02 {
  border-top-color: var(--secondary-02) !important;
}

.br-secondary-02,
.bx-secondary-02 {
  border-right-color: var(--secondary-02) !important;
}

.bb-secondary-02,
.by-secondary-02 {
  border-bottom-color: var(--secondary-02) !important;
}

.bl-secondary-02,
.bx-secondary-02 {
  border-left-color: var(--secondary-02) !important;
}

.text-secondary-03 {
  color: var(--secondary-03) !important;
}

.text-secondary-300 {
  color: var(--secondary-300) !important;
}

.bg-secondary-03 {
  background-color: var(--secondary-03) !important;
}

.b-secondary-03 {
  border-color: var(--secondary-03) !important;
}

.bt-secondary-03,
.by-secondary-03 {
  border-top-color: var(--secondary-03) !important;
}

.br-secondary-03,
.bx-secondary-03 {
  border-right-color: var(--secondary-03) !important;
}

.bb-secondary-03,
.by-secondary-03 {
  border-bottom-color: var(--secondary-03) !important;
}

.bl-secondary-03,
.bx-secondary-03 {
  border-left-color: var(--secondary-03) !important;
}

.text-secondary-04 {
  color: var(--secondary-04) !important;
}

.text-secondary-400 {
  color: var(--secondary-400) !important;
}

.bg-secondary-04 {
  background-color: var(--secondary-04) !important;
}

.b-secondary-04 {
  border-color: var(--secondary-04) !important;
}

.bt-secondary-04,
.by-secondary-04 {
  border-top-color: var(--secondary-04) !important;
}

.br-secondary-04,
.bx-secondary-04 {
  border-right-color: var(--secondary-04) !important;
}

.bb-secondary-04,
.by-secondary-04 {
  border-bottom-color: var(--secondary-04) !important;
}

.bl-secondary-04,
.bx-secondary-04 {
  border-left-color: var(--secondary-04) !important;
}

.text-secondary-05 {
  color: var(--secondary-05) !important;
}

.text-secondary-500 {
  color: var(--secondary-500) !important;
}

.bg-secondary-05 {
  background-color: var(--secondary-05) !important;
}

.b-secondary-05 {
  border-color: var(--secondary-05) !important;
}

.bt-secondary-05,
.by-secondary-05 {
  border-top-color: var(--secondary-05) !important;
}

.br-secondary-05,
.bx-secondary-05 {
  border-right-color: var(--secondary-05) !important;
}

.bb-secondary-05,
.by-secondary-05 {
  border-bottom-color: var(--secondary-05) !important;
}
.bl-secondary-05,
.bx-secondary-05 {
  border-left-color: var(--secondary-05) !important;
}

.text-secondary-06 {
  color: var(--secondary-06) !important;
}

.text-secondary-600 {
  color: var(--secondary-600) !important;
}

.bg-secondary-06 {
  background-color: var(--secondary-06) !important;
}

.b-secondary-06 {
  border-color: var(--secondary-06) !important;
}

.bt-secondary-06,
.by-secondary-06 {
  border-top-color: var(--secondary-06) !important;
}

.br-secondary-06,
.bx-secondary-06 {
  border-right-color: var(--secondary-06) !important;
}

.bb-secondary-06,
.by-secondary-06 {
  border-bottom-color: var(--secondary-06) !important;
}

.bl-secondary-06,
.bx-secondary-06 {
  border-left-color: var(--secondary-06) !important;
}

.text-secondary-07 {
  color: var(--secondary-07) !important;
}

.text-secondary-700 {
  color: var(--secondary-700) !important;
}

.bg-secondary-07 {
  background-color: var(--secondary-07) !important;
}

.b-secondary-07 {
  border-color: var(--secondary-07) !important;
}

.bt-secondary-07,
.by-secondary-07 {
  border-top-color: var(--secondary-07) !important;
}

.br-secondary-07,
.bx-secondary-07 {
  border-right-color: var(--secondary-07) !important;
}

.bb-secondary-07,
.by-secondary-07 {
  border-bottom-color: var(--secondary-07) !important;
}

.bl-secondary-07,
.bx-secondary-07 {
  border-left-color: var(--secondary-07) !important;
}

.text-secondary-08 {
  color: var(--secondary-08) !important;
}

.text-secondary-800 {
  color: var(--secondary-800) !important;
}

.bg-secondary-08 {
  background-color: var(--secondary-08) !important;
}

.b-secondary-08 {
  border-color: var(--secondary-08) !important;
}
.bt-secondary-08,
.by-secondary-08 {
  border-top-color: var(--secondary-08) !important;
}

.br-secondary-08,
.bx-secondary-08 {
  border-right-color: var(--secondary-08) !important;
}

.bb-secondary-08,
.by-secondary-08 {
  border-bottom-color: var(--secondary-08) !important;
}

.bl-secondary-08,
.bx-secondary-08 {
  border-left-color: var(--secondary-08) !important;
}

.text-secondary-09 {
  color: var(--secondary-09) !important;
}

.text-secondary-900 {
  color: var(--secondary-900) !important;
}

.bg-secondary-09 {
  background-color: var(--secondary-09) !important;
}

.b-secondary-09 {
  border-color: var(--secondary-09) !important;
}

.bt-secondary-09,
.by-secondary-09 {
  border-top-color: var(--secondary-09) !important;
}

.br-secondary-09,
.bx-secondary-09 {
  border-right-color: var(--secondary-09) !important;
}

.bb-secondary-09,
.by-secondary-09 {
  border-bottom-color: var(--secondary-09) !important;
}

.bl-secondary-09,
.bx-secondary-09 {
  border-left-color: var(--secondary-09) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.b-secondary {
  border-color: var(--secondary) !important;
}

.bt-secondary,
.by-secondary {
  border-top-color: var(--secondary) !important;
}

.br-secondary,
.bx-secondary {
  border-right-color: var(--secondary) !important;
}

.bb-secondary,
.by-secondary {
  border-bottom-color: var(--secondary) !important;
}

.bl-secondary,
.bx-secondary {
  border-left-color: var(--secondary) !important;
}
.custom-card--border-dash.custom-card--color-secondary-01,
.custom-card--border-dot.custom-card--color-secondary-01,
.custom-card--border.custom-card--color-secondary-01 {
  border-color: var(--secondary-01);
}

.custom-card--border-dash.custom-card--color-secondary-02,
.custom-card--border-dot.custom-card--color-secondary-02,
.custom-card--border.custom-card--color-secondary-02 {
  border-color: var(--secondary-02);
}

.custom-card--border-dash.custom-card--color-secondary-03,
.custom-card--border-dot.custom-card--color-secondary-03,
.custom-card--border.custom-card--color-secondary-03 {
  border-color: var(--secondary-03);
}

.custom-card--border-dash.custom-card--color-secondary-04,
.custom-card--border-dot.custom-card--color-secondary-04,
.custom-card--border.custom-card--color-secondary-04 {
  border-color: var(--secondary-04);
}

.custom-card--border-dash.custom-card--color-secondary-05,
.custom-card--border-dot.custom-card--color-secondary-05,
.custom-card--border.custom-card--color-secondary-05 {
  border-color: var(--secondary-05);
}

.custom-card--border-dash.custom-card--color-secondary-06,
.custom-card--border-dot.custom-card--color-secondary-06,
.custom-card--border.custom-card--color-secondary-06 {
  border-color: var(--secondary-06);
}

.custom-card--border-dash.custom-card--color-secondary-07,
.custom-card--border-dot.custom-card--color-secondary-07,
.custom-card--border.custom-card--color-secondary-07 {
  border-color: var(--secondary-07);
}

.custom-card--border-dash.custom-card--color-secondary-08,
.custom-card--border-dot.custom-card--color-secondary-08,
.custom-card--border.custom-card--color-secondary-08 {
  border-color: var(--secondary-08);
}

.custom-card--border-dash.custom-card--color-secondary-09,
.custom-card--border-dot.custom-card--color-secondary-09,
.custom-card--border.custom-card--color-secondary-09 {
  border-color: var(--secondary-09);
}
.custom-card--border-dash.custom-card--color-secondary,
.custom-card--border-dot.custom-card--color-secondary,
.custom-card--border.custom-card--color-secondary {
  border-color: var(--secondary);
}
.custom-card--side-border.custom-card--color-secondary-01 {
  border-left-color: var(--secondary-01);
}

.custom-card--side-border.custom-card--color-secondary-02 {
  border-left-color: var(--secondary-02);
}

.custom-card--side-border.custom-card--color-secondary-03 {
  border-left-color: var(--secondary-03);
}

.custom-card--side-border.custom-card--color-secondary-04 {
  border-left-color: var(--secondary-04);
}

.custom-card--side-border.custom-card--color-secondary-05 {
  border-left-color: var(--secondary-05);
}

.custom-card--side-border.custom-card--color-secondary-06 {
  border-left-color: var(--secondary-06);
}

.custom-card--side-border.custom-card--color-secondary-07 {
  border-left-color: var(--secondary-07);
}

.custom-card--side-border.custom-card--color-secondary-08 {
  border-left-color: var(--secondary-08);
}

.custom-card--side-border.custom-card--color-secondary-09 {
  border-left-color: var(--secondary-09);
}

.custom-card--side-border.custom-card--color-secondary {
  border-left-color: var(--secondary);
}
.custom-card--background.custom-card--color-secondary-01 {
  background-color: var(--secondary-01);
}

.custom-card--background.custom-card--color-secondary-02 {
  background-color: var(--secondary-02);
}

.custom-card--background.custom-card--color-secondary-03 {
  background-color: var(--secondary-03);
}

.custom-card--background.custom-card--color-secondary-04 {
  background-color: var(--secondary-04);
}

.custom-card--background.custom-card--color-secondary-05 {
  background-color: var(--secondary-05);
}

.custom-card--background.custom-card--color-secondary-06 {
  background-color: var(--secondary-06);
}

.custom-card--background.custom-card--color-secondary-07 {
  background-color: var(--secondary-07);
}

.custom-card--background.custom-card--color-secondary-08 {
  background-color: var(--secondary-08);
}

.custom-card--background.custom-card--color-secondary-09 {
  background-color: var(--secondary-09);
}

.custom-card--background.custom-card--color-secondary {
  background-color: var(--secondary);
}
.custom-card-header--background.custom-card-header--color-secondary-01 {
  background-color: var(--secondary-01);
}

.custom-card-header--background.custom-card-header--color-secondary-02 {
  background-color: var(--secondary-02);
}

.custom-card-header--background.custom-card-header--color-secondary-03 {
  background-color: var(--secondary-03);
}

.custom-card-header--background.custom-card-header--color-secondary-04 {
  background-color: var(--secondary-04);
}

.custom-card-header--background.custom-card-header--color-secondary-05 {
  background-color: var(--secondary-05);
}

.custom-card-header--background.custom-card-header--color-secondary-06 {
  background-color: var(--secondary-06);
}

.custom-card-header--background.custom-card-header--color-secondary-07 {
  background-color: var(--secondary-07);
}

.custom-card-header--background.custom-card-header--color-secondary-08 {
  background-color: var(--secondary-08);
}

.custom-card-header--background.custom-card-header--color-secondary-09 {
  background-color: var(--secondary-09);
}

.custom-card-header--background.custom-card-header--color-secondary {
  background-color: var(--secondary);
}

.custom-card-header--border.custom-card-header--color-secondary-01 {
  border-bottom-color: var(--secondary-01);
}

.custom-card-header--border.custom-card-header--color-secondary-02 {
  border-bottom-color: var(--secondary-02);
}

.custom-card-header--border.custom-card-header--color-secondary-03 {
  border-bottom-color: var(--secondary-03);
}

.custom-card-header--border.custom-card-header--color-secondary-04 {
  border-bottom-color: var(--secondary-04);
}

.custom-card-header--border.custom-card-header--color-secondary-05 {
  border-bottom-color: var(--secondary-05);
}

.custom-card-header--border.custom-card-header--color-secondary-06 {
  border-bottom-color: var(--secondary-06);
}

.custom-card-header--border.custom-card-header--color-secondary-07 {
  border-bottom-color: var(--secondary-07);
}

.custom-card-header--border.custom-card-header--color-secondary-08 {
  border-bottom-color: var(--secondary-08);
}

.custom-card-header--border.custom-card-header--color-secondary-09 {
  border-bottom-color: var(--secondary-09);
}
