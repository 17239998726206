@import '@zerintia/powerstone-ui/dist/powerstone-ui.min.css';

img[src*='logo-white'] {
  width: 80%;
  height: 100%;
}

td,
th {
  word-break: break-all;
}

.min-h-auto {
  min-height: auto !important;
}

.licenseItem {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}

.licenseItem svg {
  width: 4.875em;
  height: 4.875em;
  border-radius: 0.875em;
}

.licenseItem .licenseItemInfo {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;
}

.licenseItem input {
  height: 2em;
}

.licenseItem i {
  margin-left: 0.375em;
}

.license-force-white .card-body.text-white > h1,
.license-force-white .card-body.text-white > h2,
.license-force-white .card-body.text-white > h3,
.license-force-white .card-body.text-white > h4,
.license-force-white .card-body.text-white > h5,
.license-force-white .card-body.text-white > h6,
.license-force-white .card-body.text-white > span,
.license-force-white .card-body.text-white > p {
  color: #fff !important;
}

.align-end {
  text-align: end;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.overflow-visible {
  overflow: visible;
}

.border-none {
  border: none;
}

.box-shadow-none {
  box-shadow: none;
}