.navbar-application-catalog {
  width: 100%;
}
.pull-right {
  width: fit-content;
  margin-right: 0;
  margin-left: auto;
}
.application-catalog-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
}
.welcome-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
div[class^=HeaderAuth_imgContainer] {
  max-width: 100%;
}
img[class^=HeaderAuth_image] {
  max-width: 23em;
  width: 100%;
}
.applications-container-catalog {
  flex-grow: 2;
}
.app-item-catalog {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.2rem;
  border-radius: 15px;
}
.app-item-catalog svg {
  width: 4.5em;
  height: 4.5em;
  border-radius: 1em;
}
.app-item-catalog:hover {
  background-color: #ededed;
  padding: 0.2rem;
}

.applications-container-catalog > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 500px) {
  .welcome-container {
    max-width: 80%;
  }
  .applications-container-catalog  {
    max-width: 80%;
  }
  .applications-container-catalog > div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 880px) {
  .welcome-container {
    justify-content: end;
    align-items: flex-end;
    max-width: 50%;
  }
  .applications-container-catalog > div {
    grid-template-columns: repeat(1, 1fr);
  }
}
