.navLink {
  padding: 1em 2em;
}

.navLink:hover {
  background-color: lightgrey;
}

.widthContainer {
  min-width: 20em;
}
